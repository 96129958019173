<template>
  <InvoicesPage/>
</template>

<script>

import InvoicesPage from "@/views/pages/InvoicesPage";

export default {
  name: 'HomePage',
  components: {
    InvoicesPage,
  },
  data: () => ({}),
  created() {

  },
  methods: {},
}
</script>


