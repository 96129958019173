<template>
  <v-container fluid>

    <v-row>
      <v-col
          class="mb-5"
          cols="auto"
          md="12"
          sm="12"
      >
        <v-card>
          <v-data-table
              :footer-props="{
                showFirstLastPage: true,
                pageText: '{0}-{1} od {2}',
                itemsPerPageText: 'Naloga po strani',
                itemsPerPageOptions: [10,20,-1],
              }"
              :headers="headers"
              :items="invoices"
              :loading="loading"
              :search="searchString"
              class="elevation-1"
              multi-sort
          >
            <template v-slot:item.created_at="{ item }">
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>

            <template v-slot:top>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Nalozi</v-toolbar-title>

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>

                <v-text-field
                    v-model="searchString"
                    append-icon="mdi-magnify"
                    hide-details
                    label="Pretraga"
                    single-line
                ></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog
                    v-model="dialog"
                    max-width="700px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mb-2"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                      Dodaj
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ formTitle }}
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-form
                            ref="form"
                            v-model="formValid">
                          <v-row>
                            <v-col cols="12" hidden md="6" sm="12">
                              <v-text-field
                                  v-model="editedItem.id"
                                  label="Id"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12" v-if="editedItem.id">
                              <v-text-field
                                  v-model="editedItem.name"
                                  :rules="fieldRules.nameRules.concat(fieldRules.max200Chars)"
                                  disabled
                                  label="Nalog"
                                  readonly
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-textarea
                                  v-model="editedItem.description"
                                  :rules="fieldRules.max1000Chars.concat(fieldRules.descriptionRules)"
                                  clearable
                                  counter="1000"
                                  label="Opis"
                                  required
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-file-input
                                  v-model="editedItem.image"
                                  :rules="fieldRules.fileRequireAndTypeRules.check(editedItem.id,editedItem.image)"
                                  accept="image/jpg, image/jpeg, image/png"
                                  counter
                                  label="Slika"
                                  prepend-icon="mdi-camera"
                                  show-size
                                  small-chips
                              ></v-file-input>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-autocomplete
                                  v-model="editedItem.user_id"
                                  :items="users"
                                  :rules="fieldRules.userRules"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  label="Ime i prezime"
                                  required
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-autocomplete
                                  v-model="editedItem.vehicle_id"
                                  :items="vehicles"
                                  :rules="fieldRules.customerRules"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  label="Vozilo"
                                  required
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-autocomplete
                                  v-model="editedItem.customer_id"
                                  :items="partners"
                                  :rules="fieldRules.customerRules"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  label="Klijent"
                                  required
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6" sm="6">
                              <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="editedItem.date"
                                  min-width="auto"
                                  offset-y
                                  transition="scale-transition"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="editedItem.date"
                                      :rules="fieldRules.dateRules"
                                      label="Datum"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      required
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedItem.date"
                                    no-title
                                    scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      color="primary"
                                      text
                                      @click="menu = false"
                                  >
                                    Otkaži
                                  </v-btn>
                                  <v-btn
                                      color="primary"
                                      text
                                      @click="$refs.menu.save(editedItem.date)"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>

                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="red darken-1 font-weight-bold"
                          text
                          @click="close"
                      >
                        Zatvori
                      </v-btn>
                      <v-btn
                          :disabled="!enableSave"
                          color="info font-weight-bold"
                          text
                          @click="validateForm"
                      >
                        Sačuvaj
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogImage"
                    height="auto"
                    width="auto"
                >
                  <v-card>
                    <v-img
                        v-if="itemImage"
                        :src="itemImage"
                        max-height="600"
                        max-width="600"
                        style="background-size: cover;"
                    ></v-img>
                    <v-img
                        v-else
                        aspect-ratio="2"
                        contain
                        height="250px"
                        src="@/assets/default-user.png"
                        style="background-size: cover;"
                        width="250px"
                    ></v-img>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      small
                      v-bind="attrs"
                      @click="downloadItem(item)"
                      v-on="on"
                  >
                    mdi-download
                  </v-icon>
                </template>
                <span>Preuzmi</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2 center"
                      v-bind="attrs"
                      @click="showImage(item)"
                      v-on="on"
                  >
                    mdi-fit-to-screen
                  </v-icon>

                </template>
                <span>Prikaži fotografiju</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      small
                      v-bind="attrs"
                      @click="editItem(item)"
                      v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Izmijeni</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      color="red darken-3"
                      small
                      v-bind="attrs"
                      @click="showDeleteModal(item)"
                      v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Izbriši</span>
              </v-tooltip>
            </template>

          </v-data-table>
        </v-card>
      </v-col>

      <v-dialog
          v-model="dialogDelete"
          max-width="650"
      >
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            Brisanje Naloga
          </v-card-title>
          <v-card-text>
            <v-container>

              <p>
                Da li ste sigurni da želite da izbrišete nalog <b>{{ invoiceTitle }}</b>?
              </p>

              <v-form
                  ref="formDelete"
                  v-model="formValidDelete">
                <v-row>
                </v-row>
              </v-form>
            </v-container>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1 font-weight-bold"
                text
                @click="closeDeleteModal"
            >
              Ne želim
            </v-btn>
            <v-btn
                :disabled="!enableSave"
                color="green darken-1 font-weight-bold"
                text
                @click="validateDeleteForm"
            >
              Želim
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-container>
</template>

<script>
import {invoiceHeaders} from '@/mixins/data-table-headers'
import {fieldRules} from "@/mixins/field-rules";
import {defaultInvoice} from "@/mixins/default-items";

export default {
  name: 'InvoicesPage',
  components: {},
  data: () => ({
    page: 1,
    positions: [],
    searchString: '',
    dialog: false,
    dialogDelete: false,
    dialogImage: false,
    headers: [],
    invoices: [],
    formTitle: 'Novi unos',
    formValid: true,
    formValidDelete: true,
    invoiceTitle: "",
    editedItem: {},
    editedItemDeleted: {},
    itemImage: null,
    editedIndex: -1,
    description: '',
    fieldRules: null,
    enableSave: true,
    menu: false,
    users: [],
    partners: [],
    vehicles: [],
    options: {},
    loading: true,
    serverLength: 0,
  }),
  created() {
    this.headers = invoiceHeaders
    this.fieldRules = fieldRules
    this.editedItem = Object.assign({}, defaultInvoice)
    this.loadAllInvoices();
    this.loadAllUsers();
    this.loadAllPartners();
    this.loadAllVehicles();
  },
  methods: {
    async loadAllInvoices(data) {
      this.loading = true
      await this.$store.dispatch('invoices/getAllInvoices', data).then((res) => {
        this.loading = false
        this.invoices = res.data
        this.serverLength = res.data.total
      });
    },

    async loadAllUsers() {
      await this.$store.dispatch('users/getAllUsers').then(res => {
        this.users = res.data
      })
    },
    async loadAllPartners() {
      await this.$store.dispatch('partners/getAllPartners').then(res => {
        this.partners = res.data
      })
    },
    async loadAllVehicles() {
      await this.$store.dispatch('vehicles/getAllVehicles').then(res => {
        this.vehicles = res.data
      })
    },
    async deleteInvoice() {
      this.enableSave = false
      await this.$store.dispatch('invoices/deleteInvoice', {id: this.editedItemDeleted.id}).then((res) => {
        if (res.success) {
          this.loadAllInvoices();
          this.$store.dispatch('showSnackbar', {text: 'Uspješno izbrisano', color: 'green'})
          this.closeDeleteModal()
        } else {
          this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
        }
      }).catch(() => {
        this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
      }).finally(() => {
        this.enableSave = true
      })
    },

    editItem(item) {
      this.editedItem = this.invoices.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.formTitle = item.name
      this.editedItem.customer_id = parseInt(item.customer_id)
      this.editedItem.vehicle_id = parseInt(item.vehicle_id)
      this.editedItem.user_id = parseInt(item.user_id)
      this.dialog = true
    },

    validateForm() {
      if (this.$refs.form.validate())
        this.save()
    },

    createFormData() {
      let formData = new FormData();
      if (this.editedItem.image) {
        formData.append('image', this.editedItem.image)
      }
      if (this.editedItem.id) {
        formData.append('_method', 'POST')
        formData.append('id', this.editedItem.id)
      } else {
        formData.append('id', '')
      }
      formData.append('name', this.editedItem.name)
      formData.append('date', this.editedItem.date)
      formData.append('customer_id', this.editedItem.customer_id)
      formData.append('vehicle_id', this.editedItem.vehicle_id)
      formData.append('user_id', this.editedItem.user_id)
      formData.append('description', this.editedItem.description ? this.editedItem.description : '')
      return formData
    },

    validateDeleteForm() {
      if (this.$refs.formDelete.validate())
        this.deleteInvoice()
    },
    async save() {
      this.enableSave = false
      let formData = this.createFormData()
      let path = 'saveInvoice'
      if (this.editedItem.id) {
        path = 'updateInvoice'
      }

      await this.$store.dispatch('invoices/' + path, formData).then((res) => {
        if (res.success) {
          this.loadAllInvoices();
          this.$store.dispatch('showSnackbar', {text: 'Uspješno sačuvano, nalog br. ' + res.data.name, color: 'green'});
          this.close()
        } else {
          this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$store.dispatch('errorMessages/errorMapper', error.response.data.message).then(
              e => this.$store.dispatch('showSnackbar', {text: e, color: 'red'})
          )
        } else {
          this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
        }
      }).finally(() => {
        this.enableSave = true
        this.formTitle = "Novi unos"
      })
    },
    downloadItem(item) {
      let file = document.createElement('a');
      this.$store.dispatch('invoices/downloadInvoice', item).then((res) => {
        file.href = res.path;
        file.setAttribute('target', "_blank")
        document.body.appendChild(file);
        file.click();
        this.$store.dispatch('showSnackbar', {text: 'Uspješno preuzeto', color: 'green'});
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$store.dispatch('errorMessages/errorMapper', error.response.data.message).then(
              e => this.$store.dispatch('showSnackbar', {text: e, color: 'red'})
          )
        } else {
          this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
        }
      })
    },

    showDeleteModal(item) {
      this.invoiceTitle = item.name
      this.dialogDelete = true
      this.editedItemDeleted = item
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultInvoice)
        this.editedIndex = -1
      })
      this.description = ""
      this.$refs.form.resetValidation()
      this.formTitle = "Novi unos"
    },

    closeDeleteModal() {
      this.invoiceTitle = ""
      this.dialogDelete = false
      this.editedItemDeleted = null
    },

    showImage(item) {
      this.dialogImage = true
      if (item.image_path) {
        this.itemImage = process.env.VUE_APP_BACKEND_URL.concat(item.image_path)
        this.itemImage = this.itemImage.replace('public', 'storage')
      } else {
        this.itemImage = null
      }
    },


  },

  watch: {
    dialog(val) {
      // if (val && !this.editedItem.name) {
      //   this.$store.dispatch('invoices/getInvoiceNumber').then((res) => {
      //     if (res.success) {
      //       this.editedItem.name = res.data.name
      //     } else {
      //       this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
      //     }
      //   }).catch((error) => {
      //     if (error.response.status === 422) {
      //       this.$store.dispatch('errorMessages/errorMapper', error.response.data.message).then(
      //           e => this.$store.dispatch('showSnackbar', {text: e, color: 'red'})
      //       )
      //     } else {
      //       this.$store.dispatch('showSnackbar', {text: 'Došlo je do greške, prijavite podršci', color: 'red'});
      //     }
      //   }).finally(() => {
      //     this.enableSave = true
      //     this.formTitle = "Novi unos"
      //   })
      //
      // }
      return val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDeleteModal()
    },
    // options: {
    //   handler(e) {
    //
    //     if (e.page !== this.page) {
    //       this.page = e.page
    //       this.loadAllInvoices(e.page)
    //     }
    //   },
    //   deep: true,
    // },

  },
}
</script>
